import React, {useContext, useEffect, useState} from "react";
import {DeviceHomeLocation} from "../components/DeviceHomeLocation";
import {Alert, Grid, Paper} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {AppHeader} from "../components/AppHeader";
import TextField from "@mui/material/TextField";
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {Form} from "formik";
import DeviceService from "../services/DeviceService";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    title: {
        color: "white",
    },

    contentPane: {
        display: "flex",
        justifyContent: "center",
    },

    paper_transparent: {
        display: 'flex',
        width: "100%",
        backgroundColor: "transparent !important",
        border: "none !important",
        margin: "0px",
        paddingBottom: "15px",
        justifyContent: 'center',
        alignItems: 'center',
    },

    form_contact_wrapper: {
        borderRadius: 14,
        border: "4px solid rgba(255,255,255,0.25)",
        height: 'auto',
        width: "calc(100% - 30px) !important",
        marginLeft: "15px !important",
        maxWidth: '440px',
        textAlign: "left",
        marginBottom: "12px !important",
        marginTop: "12px !important",
        marginRight: "auto",
        padding: "0.6rem",
        '& .MuiInputBase-formControl': {
            width: "100% !important",
        },
        '& .MuiButtonBase-root': {
            marginBottom: "5px !important",
        },
    },

    textInputField: {
        fontFamily: "HurmeDesign",
        fontSize: "15px",
        backgroundColor: "white",
        border: "1, solid",
        borderRadius: "15px",
        marginBottom: "10px !important",
    },

    button: {
        display: "flex",
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        paddingLeft: "30px",
        textAlign: "left",
        marginLeft: "5%",
        marginRight: "5%",
        justifyContent: "flex-start",
        alignItems: "end",
        border: "1px",
        borderColor: theme.palette.greyScale.dark,
    },
}));

export const DeviceDetail = props => {
    const classes = useStyles();

    const location = useLocation();
    const history = useHistory();
    const [device, setDevice] = useState(location.state.device);
    const [user, setUser] = useState(location.state.currentUser);

    const handleDeviceNameChange = (event) => {
        console.log("handleDeviceNameChange")
        setDevice(previousState => {
           return {
               ...previousState,
               name: event.target.value,
           }
        });
    };

    const handleDevicePhoneChange = (event, i) => {
        let newPhone = {
            name: "",
            ordinality: i + 1,
            phoneNo: event.target.value
        }
        setDevice(previousState => {
            return {
                ...previousState,
                phoneNumbers: Object.assign([], previousState.phoneNumbers, {[i]: newPhone})
            }
        });
    };

    const handleDeviceSave = (event) => {
        event.preventDefault();
        console.log("saving device name: " + JSON.stringify(device));
        const saveDeviceName = async () => {
            const dev = await DeviceService.saveDevice(device);
            //setDevice(dev);
        };
        saveDeviceName().catch((err) => {
            console.log("Error saving device name." + err);
        });
    };

    return (
        <div style={{ textAlign: "center" }}>
            <AppHeader />
            <div className="content-pane content-pane-green">
                <Grid container direction="row" style={{display:"block"}}>
                    <Grid item xs={12} className="title_heading">
                        <h1>Redigera Enhet</h1>
                        <ArrowBackOutlinedIcon onClick={() => history.push(
                            {pathname: '/settings', state: { currentUser: user}})} />
                    </Grid>
                    <form onSubmit={handleDeviceSave} id="updateDeviceNameForm">
                        <Grid item xs={12}>
                            <div className="form-field-label">Enhetens namn:</div>
                            <TextField
                                fullWidth
                                id="deviceName"
                                name="deviceName"
                                variant="outlined"
                                placeholder="Beskrivning"
                                defaultValue={device.name}
                                className={classes.button}
                                onChange={(event) => handleDeviceNameChange(event)}
                            />
                        </Grid>
                        <DevicePhoneNumbers device={device}
                            handleDevicePhoneChange={handleDevicePhoneChange}/>
                        <Grid item xs={12}>
                            <Button color="primary" variant="contained" type="submit" form="updateDeviceNameForm">
                                Spara
                            </Button>
                        </Grid>
                    </form>
                    <Grid item xs={12} className={classes.form_contact_wrapper}>
                        <h2>Geofencing:</h2>
                        {device.family === "reachfar" ?
                            <DeviceHomeLocation device={device}/>
                            :
                            <p style={{color: "black", textAlign: "center"}}>Enheten stöder inte positionering.</p>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

function DevicePhoneNumbers(props) {
    const classes = useStyles();
    let device = props.device;
    let phoneNos = device.phoneNumbers;
    let rows = 0;
    let elements = [];
    if (device.family === "reachfar") {
        console.log("Is REACHFAR")
        rows = 2;
        for (let i = 0; i < rows; i++) {
            let phoneNumber = "";
            if (device.phoneNumbers && device.phoneNumbers[i]) {
                console.log("Have number(s)" + JSON.stringify(device.phoneNumbers[i]));
                phoneNumber = device.phoneNumbers[i].phoneNo;
            }
            let text = "";
            switch (i) {
                case 0:
                    text = "SOS + knapp 1";
                    break;
                case 1:
                    text = "knapp 2";
                    break;
                default:
                    text = "";
            }
            elements.push(
                <Grid item xs={12}>
                    <div className="form-field-label">Telefon #{i+1} <small><i>( {text} )</i></small> :</div>
                    <TextField
                        fullWidth
                        id={"devicePhoneNumber" + phoneNos}
                        name="devicePhoneNumber1"
                        variant="outlined"
                        placeholder="Telefonnummer"
                        defaultValue={phoneNumber}
                        className={classes.button}
                        onChange={(event) => props.handleDevicePhoneChange(event, i)}
                    />
                </Grid>
            );
        }
        // dels redan sparade men också så att det kan bli två tomma rader om det är reachfar.
        // kolla upp hur man returnerar JSX med strängvariabler. (kolla id= nedan, funkar?)
    }
    return elements;
};
